import React from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  AppBar,
  Collapse,
  IconButton,
  Toolbar,
  Typography,
  Menu,
  MenuItem,
} from "@material-ui/core";
import SortIcon from "@material-ui/icons/Sort";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {
  drvo,
  naZivotot,
  quote,
  dobredojdovte,
  author,
  sliki,
  videos,
  sliki1,
  sliki2,
  sliki3,
  sliki4,
  video1,
  video2,
  video3,
  video4,
  video5,
  video6,
  video7,
  video8,
} from "../Utils/TextConst";
import { Link as Scroll } from "react-scroll";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import Slide from "@material-ui/core/Slide";
import PropTypes from "prop-types";
import MediaDialog from "../Media/MediaDialog";

const styles = {
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    fontFamily: "Nunito",
  },
  appbar: {
    background: "none",
    background: "rgba(0,0,0,0.1)",
  },
  icon: {
    color: "#000000",
    fontSize: "2rem",
  },
  textColor: {
    color: "#000000",
  },
  appBarTitle: {
    flexGrow: "1",
  },
  titleColor: {
    color: "#5AFF",
  },
  mainTitle: {
    fontSize: "3rem",
  },
  container: {
    textAlign: "center",
  },
  goDown: {
    color: "#5AFF",
    fontSize: "5rem",
  },
  quote: {
    color: "black",
    fontStyle: "bold",
    fontFamily: "Nunito",
    fontSize: "1.2rem",
  },
  authorCss: {
    color: "#5AFF",
    fontFamily: "Nunito",
    fontSize: "1.2rem",
  },
  multiMedia: {
    color: "#000000",
    cursor: "pointer",
    fontSize: "1.7rem",
  },
  multiMediaVideo: {
    color: "#000000",
    cursor: "pointer",
    fontSize: "1.7rem",
  },
};

function HideOnScroll(props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({ target: window ? window() : undefined });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

HideOnScroll.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: false,
      anchorImages: null,
      anchorVideo: null,
      show: false,
      show2: false,
      show3: false,
      show4: false,
      show5: false,
      videoFile: "",
    };

    this.handleClick = this.handleClick.bind(this);
    this.handleClickVideo = this.handleClickVideo.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleCloseVideo = this.handleCloseVideo.bind(this);
    this.showImageDialog = this.showImageDialog.bind(this);
    this.showImageDialog2 = this.showImageDialog2.bind(this);
    this.showImageDialog3 = this.showImageDialog3.bind(this);
    this.showImageDialog4 = this.showImageDialog4.bind(this);
    this.showImageDialog5 = this.showImageDialog5.bind(this);
    this.showDialog = this.showDialog.bind(this);
    this.showDialog2 = this.showDialog2.bind(this);
    this.showDialog3 = this.showDialog3.bind(this);
    this.showDialog4 = this.showDialog4.bind(this);
    this.showDialog5 = this.showDialog5.bind(this);
    this.closeDialog = this.closeDialog.bind(this);
    this.closeDialog2 = this.closeDialog2.bind(this);
    this.closeDialog3 = this.closeDialog3.bind(this);
    this.closeDialog4 = this.closeDialog4.bind(this);
    this.closeDialog5 = this.closeDialog5.bind(this);
  }

  componentDidMount() {
    this.setState({ checked: true });
  }

  handleClick(event) {
    this.setState({
      anchorImages: event.currentTarget,
    });
  }

  handleClickVideo(event) {
    this.setState({
      anchorVideo: event.currentTarget,
    });
  }

  handleClose() {
    this.setState({
      anchorImages: null,
    });
  }

  handleCloseVideo() {
    this.setState({
      anchorVideo: null,
    });
  }

  showImageDialog() {
    this.showDialog();

    this.handleClose();
  }

  showImageDialog2() {
    this.showDialog2();

    this.handleClose();
  }

  showImageDialog3() {
    this.showDialog3();

    this.handleClose();
  }

  showImageDialog4() {
    this.showDialog4();

    this.handleClose();
  }

  showImageDialog5(event) {
    this.setState({ videoFile: event.target.getAttribute("value") });
    this.showDialog5();
    this.handleCloseVideo();
  }

  showDialog() {
    this.setState({ show: true });
  }

  showDialog2() {
    this.setState({ show2: true });
  }

  showDialog3() {
    this.setState({ show3: true });
  }

  showDialog4() {
    this.setState({ show4: true });
  }

  showDialog5() {
    this.setState({ show5: true });
  }

  closeDialog() {
    this.setState({ show: false });
  }

  closeDialog2() {
    this.setState({ show2: false });
  }

  closeDialog3() {
    this.setState({ show3: false });
  }

  closeDialog4() {
    this.setState({ show4: false });
  }

  closeDialog5() {
    this.setState({ show5: false });
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root} id="header">
        <HideOnScroll>
          <AppBar className={classes.appbar} elevation={0}>
            <Toolbar>
              <h1 className={`${classes.appBarTitle} ${classes.textColor}`}>
                {drvo} <span className={classes.titleColor}>{naZivotot}</span>
              </h1>
              <h2 className={classes.multiMedia} onClick={this.handleClick}>
                {sliki}
              </h2>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <h2
                className={classes.multiMediaVideo}
                onClick={this.handleClickVideo}
              >
                {videos}
              </h2>
              {/* <IconButton onClick={this.handleClick}>
                    <SortIcon className={classes.icon}/>                  
                </IconButton>    */}
              <Menu
                id="simple-menu-account"
                anchorEl={this.state.anchorImages}
                open={Boolean(this.state.anchorImages)}
                onClose={this.handleClose}
              >
                <MenuItem onClick={this.showImageDialog}>{sliki1}</MenuItem>
                <MenuItem onClick={this.showImageDialog2}>{sliki2}</MenuItem>
                <MenuItem onClick={this.showImageDialog3}>{sliki3}</MenuItem>
                <MenuItem onClick={this.showImageDialog4}>{sliki4}</MenuItem>
              </Menu>
              <Menu
                id="simple-menu-account-videos"
                anchorEl={this.state.anchorVideo}
                open={Boolean(this.state.anchorVideo)}
                onClose={this.handleCloseVideo}
              >
                <MenuItem onClick={this.showImageDialog5} value="1.mp4">
                  {video1}
                </MenuItem>
                <MenuItem onClick={this.showImageDialog5} value="2.mp4">
                  {video2}
                </MenuItem>
                <MenuItem onClick={this.showImageDialog5} value="3.mp4">
                  {video3}
                </MenuItem>
                <MenuItem onClick={this.showImageDialog5} value="4.mp4">
                  {video4}
                </MenuItem>
                <MenuItem onClick={this.showImageDialog5} value="5.mp4">
                  {video5}
                </MenuItem>
                <MenuItem onClick={this.showImageDialog5} value="6.mp4">
                  {video6}
                </MenuItem>
                <MenuItem onClick={this.showImageDialog5} value="7.mp4">
                  {video7}
                </MenuItem>
                <MenuItem onClick={this.showImageDialog5} value="8.mp4">
                  {video8}
                </MenuItem>
              </Menu>
            </Toolbar>
          </AppBar>
        </HideOnScroll>

        <Collapse
          in={this.state.checked}
          {...(this.state.checked ? { timeout: 1000 } : {})}
          collapsedHeight={50}
        >
          <div className={classes.container}>
            <h1 className={classes.mainTitle}>
              {dobredojdovte}
              <span className={classes.titleColor}>&nbsp;{naZivotot}</span>
            </h1>

            <Typography className={classes.quote}>
              <i>
                {quote}
                <span className={classes.authorCss}>{author}</span>
              </i>
            </Typography>
            <Scroll to="cards" smooth={true}>
              <IconButton>
                <ExpandMoreIcon className={classes.goDown} />
              </IconButton>
            </Scroll>
          </div>
        </Collapse>
        <MediaDialog
          open={this.state.show}
          close={this.closeDialog}
          folder="slide"
        />
        <MediaDialog
          open={this.state.show2}
          close={this.closeDialog2}
          folder="slide2"
        />
        <MediaDialog
          open={this.state.show3}
          close={this.closeDialog3}
          folder="slide3"
        />
        <MediaDialog
          open={this.state.show4}
          close={this.closeDialog4}
          folder="slide4"
        />
        <MediaDialog
          open={this.state.show5}
          close={this.closeDialog5}
          file={this.state.videoFile}
          isVideo={true}
        />
      </div>
    );
  }
}

export default withStyles(styles)(Header);
