import React from 'react';
import { Button } from '@material-ui/core';
import Home from './Home/Home';
import {BrowserRouter,Switch,Route} from "react-router-dom";

class App extends React.Component{

  constructor(props){
    super(props);
  }

  render(){
    return  (
      <>
        <BrowserRouter>              
          <Switch>
            <Route exact path="/" component={Home}/>
          </Switch>
        </BrowserRouter>       
      </>
    );
  }

}

export default App;
