import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { CssBaseline, Grid, IconButton } from "@material-ui/core";
import Header from "../Header/Header";
import TextCard from "../Text/TextCard";
import Footer from "../Footer/Footer";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import { Link as Scroll } from "react-scroll";

const styles = {
  root: {
    minHeight: "100vh",
    backgroundImage: `url(${
      process.env.PUBLIC_URL + "/images/background.jpg"
    })`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
  goDown: {
    color: "#5AFF",
    fontSize: "5rem",
  },
};

const Home = ({ classes }) => {
  return (
    <div className={classes.root}>
      <CssBaseline />
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={12}>
          <Header />
        </Grid>
        <Grid
          item
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          xs={12}
        >
          <Grid item md={2} />
          <Grid item xs={12} md={8}>
            <TextCard />
          </Grid>
          <Grid item md={2} />
        </Grid>
        <Grid item xs={12}>
          <Scroll to="header" smooth={true}>
            <IconButton>
              <ExpandLessIcon className={classes.goDown} />
            </IconButton>
          </Scroll>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Footer />
      </Grid>
    </div>
  );
};

export default withStyles(styles)(Home);
