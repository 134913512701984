export const drvoNaZivototUpperCase = "ДРВО НА ЖИВОТОТ";
export const drvoNaZivototAspasijasSubtitle =
  "На семејството на Аспасија и Климе и најблиските семејства";
export const drvo = "Дрво на";
export const naZivotot = "Животот";
export const dobredojdovte = "Добредојдовте во Дрвото на";
export const quote =
  '"Во младоста човек треба да љуби,во зрелоста да пишува книги, а на стари години да ја кажува вистината." ';
export const author = "- Henry de Montherlant";
export const prvDel = "Прв дел";
export const prvDelSubTitle = "Посветено на мојата сопруга Вера";
export const vtorDel = "Втор дел";
export const vtroDelSubTitle =
  "Мојте Универзитети – хронологија на животот на моето семејство и најблиските";
export const zavrsniBeleskiTitle = "Завршни белешки";
export const zavrsniBeleskiSubTitle =
  "Завршни белешки на мојата животна хронологија";
export const readTitle = "Читај";
export const viewImages = "Отвори";
export const sliki = "Слики";
export const videos = "Видеа";
export const copyright = "© 2024 Arnaudovci";
export const close = "Затвори";
export const ljupcoNaste = "Белешки од Љупчо Арнаудовски";
export const sliki1 = "Климе и Аспасија со роднините";
export const sliki2 = "Држуби покрај езеро";
export const sliki3 = "Роденденски забави и дружби на внуците";
export const sliki4 = "Пејсажи од Охрид од сите времиња";
export const video1 = "Канео";
export const video2 = "Дедо Насте";
export const video3 = "Охрид-куќа ручек";
export const video4 = "Манастир св.Наум Охридски";
export const video5 = "Охрид-куќа гледање кошарка";
export const video6 = "Охрид-куќа плажа";
export const video7 = "Охрид-куќа chillout";
export const video8 = "Охрид-куќа Мишо ручек ";
export const goranBlackWhite = "Црно бели фотографии на Горан Арнаудовски";
export const goranMladBorec = '"Млад Борец" на Горан Арнаудовски';
export const goranSlajdovi = "Уметнички слајдови на Горан Арнаудовски";
export const goranMarketing = "Маркетинг фотографии на Горан Арнаудовски";
