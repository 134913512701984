import React, { useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import ImageCard from "./ImageCard";
import { Grid } from "@material-ui/core";
import MediaDialog from "../Media/MediaDialog";
import {
  drvoNaZivototUpperCase,
  drvoNaZivototAspasijasSubtitle,
  prvDel,
  prvDelSubTitle,
  vtorDel,
  vtroDelSubTitle,
  zavrsniBeleskiTitle,
  zavrsniBeleskiSubTitle,
  sliki,
  videos,
  ljupcoNaste,
  goranBlackWhite,
  goranMladBorec,
  goranSlajdovi,
  goranMarketing,
} from "../Utils/TextConst";

const styles = {
  root: {
    minHeight: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  root2: {
    minHeight: "100vh",
  },
  link: {
    textDecoration: "none",
  },
};

const TextCard = ({ classes }) => {
  const [animation, setAnimation] = useState(false);
  const [showMediaDialog, setShowMediaDialog] = useState(false);
  const [selectedTitle, setSelectedTitle] = useState("");

  const updatePosition = () => {
    const offetSetHeight =
      window.document.getElementById("header").offsetHeight;
    if (window.pageYOffset > offetSetHeight * 0.7) {
      setAnimation(true);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", updatePosition);
    return () => window.removeEventListener("scroll", updatePosition);
  }, []);

  const handleOpenMediaDialog = (t) => {
    setSelectedTitle(t);
    setShowMediaDialog(true);
  };

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
    >
      <Grid
        container
        item
        direction="row"
        justify="center"
        alignItems="strech"
        className={classes.root2}
        id="cards"
      >
        <Grid item xs={12} md={6}>
          <ImageCard
            cardImage={process.env.PUBLIC_URL + "/images/klimeAspasija.jpg"}
            title={drvoNaZivototUpperCase}
            desc={drvoNaZivototAspasijasSubtitle}
            checked={animation}
            images={false}
            file="drvo_na_zivotot.pdf"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <ImageCard
            cardImage={process.env.PUBLIC_URL + "/images/naste_vera.jpg"}
            title={prvDel}
            desc={prvDelSubTitle}
            checked={animation}
            images={false}
            file="za_vera.pdf"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <ImageCard
            cardImage={process.env.PUBLIC_URL + "/images/del2.jpg"}
            title={vtorDel}
            desc={vtroDelSubTitle}
            checked={animation}
            images={false}
            file="rodoslovo_Drvo.pdf"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <ImageCard
            cardImage={process.env.PUBLIC_URL + "/images/naste_masina.jpeg"}
            title={zavrsniBeleskiTitle}
            desc={zavrsniBeleskiSubTitle}
            checked={animation}
            images={false}
            file="zavrsni_beleski.pdf"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <ImageCard
            cardImage={process.env.PUBLIC_URL + "/images/ohrid_site.jpg"}
            title={ljupcoNaste}
            desc={null}
            checked={animation}
            images={false}
            file="ljupco.pdf"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <ImageCard
            cardImage={process.env.PUBLIC_URL + "/images/by_passing_1.jpg"}
            title={goranBlackWhite}
            desc="goranBlackWhite"
            checked={animation}
            images={true}
            file=""
            onOpenMediaDialog={handleOpenMediaDialog}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <ImageCard
            cardImage={
              process.env.PUBLIC_URL + "/images/naslovna_mladborec.jpg"
            }
            title={goranMladBorec}
            desc="goranMladBorec"
            checked={animation}
            images={true}
            file=""
            onOpenMediaDialog={handleOpenMediaDialog}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <ImageCard
            cardImage={process.env.PUBLIC_URL + "/images/naslovna_slajdovi.jpg"}
            title={goranSlajdovi}
            desc="goranSlajdovi"
            checked={animation}
            images={true}
            file=""
            onOpenMediaDialog={handleOpenMediaDialog}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <ImageCard
            cardImage={
              process.env.PUBLIC_URL + "/images/naslovna_marketing.jpg"
            }
            title={goranMarketing}
            desc="goranMarketing"
            checked={animation}
            images={true}
            file=""
            onOpenMediaDialog={handleOpenMediaDialog}
          />
        </Grid>
      </Grid>
      <MediaDialog
        open={showMediaDialog}
        close={() => setShowMediaDialog(false)}
        folder={selectedTitle}
        isVideo={false}
      />
    </Grid>
  );
};

export default withStyles(styles)(TextCard);
