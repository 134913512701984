import React, { useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import { close } from "../Utils/TextConst";
import Carousel from "react-images";
import Images1 from "./images1.json";
import Images2 from "./images2.json";
import Images3 from "./images3.json";
import Images4 from "./images4.json";
import Images5 from "./images5.json";
import Images6 from "./images6.json";
import Images7 from "./images7.json";
import Images8 from "./images8.json";
import ReactPlayer from "react-player";

const styles = {
  root: {
    background: "rgba(0,0,0,0.5)",
  },
};

const MediaDialog = ({
  classes,
  open,
  close: handleClose,
  folder,
  isVideo,
  file,
}) => {
  const [images, setImages] = useState([]);

  useEffect(() => {
    setImages(getImages());
  }, [folder]);

  const getImages = () => {
    let images = [];
    const imagesData = {
      slide: Images1,
      slide2: Images2,
      slide3: Images3,
      slide4: Images4,
      goranBlackWhite: Images5,
      goranMladBorec: Images6,
      goranSlajdovi: Images7,
      goranMarketing: Images8,
    };
    const selectedImages = imagesData[folder];
    if (selectedImages) {
      selectedImages.forEach((image) => {
        const imagePath = {
          src: process.env.PUBLIC_URL + `images/${folder}/${image.src}`,
        };
        images.push(imagePath);
      });
    }
    return images;
  };

  const showContent = () => {
    if (isVideo) {
      const videoUrl = `videos/${file}`;
      return (
        <ReactPlayer
          className="react-player fixed-bottom"
          url={videoUrl}
          width="100%"
          height="100%"
          controls={true}
        />
      );
    } else {
      return <Carousel showArrows={true} views={images} />;
    }
  };

  return (
    <div>
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        onClose={handleClose}
        fullWidth
        PaperProps={{
          style: {
            backgroundColor: "rgba(0,0,0, 0.6)",
          },
        }}
      >
        <DialogContent>{showContent()}</DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            {close}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default withStyles(styles)(MediaDialog);
