import React from "react";
import { Box, Grid, Typography } from "@material-ui/core";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import { copyright } from "../Utils/TextConst";
import styles from "./Footer.module.css"; // Import the CSS module

const Footer = () => {
  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      className={styles.root}
    >
      <Grid item xs={12} className={styles.copyright}>
        <Box display="flex" flexDirection="row">
          <Box>
            <Typography variant="h6" className={styles.title}>
              {copyright}
            </Typography>
          </Box>
          <Box>
            <a href={"mailto:bocomir333@gmail.com"}>
              <MailOutlineIcon className={styles.email} />
            </a>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Footer;
