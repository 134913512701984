import React, { useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { Collapse } from "@material-ui/core";
import { readTitle, viewImages, goranBlackWhite } from "../Utils/TextConst";
import MediaDialog from "../Media/MediaDialog";

const styles = {
  root: {
    maxWidth: 654,
    background: "rgba(0,0,0,0.5)",
    margin: "20px",
  },
  media: {
    height: 450,
  },
  title: {
    fontFamily: "Nunito",
    fontWeight: "bold",
    fontSize: "2rem",
    color: "#fff",
  },
  desc: {
    fontFamily: "Nunito",
    fontWeight: "bold",
    fontSize: "1.1rem",
    color: "#ddd",
  },
  cardContent: {},
  actionArea: {},
};

const ImageCard = ({
  classes,
  checked,
  title,
  desc,
  cardImage,
  images,
  file,
  onOpenMediaDialog,
}) => {
  const [show, setShow] = useState(false);

  const openPdf = () => {
    if (!images) {
      window.open(process.env.PUBLIC_URL + "/pdfs/" + file, "_blank");
    } else {
      setShow(true);
    }
  };

  const handleCardClick = (e) => {
    if (images) {
      onOpenMediaDialog(desc);
    } else {
      openPdf();
    }
  };

  return (
    <>
      <Collapse in={checked} {...(checked ? { timeout: 1000 } : {})}>
        <Card className={classes.root} onClick={handleCardClick}>
          <CardActionArea className={classes.actionArea}>
            <CardMedia
              className={classes.media}
              image={cardImage}
              title={title}
            />
            <CardContent className={classes.cardContent}>
              <Typography
                gutterBottom
                variant="h5"
                component="h2"
                className={classes.title}
              >
                {title}
              </Typography>
              <Typography
                variant="body2"
                color="textSecondary"
                component="p"
                className={classes.desc}
              >
                {desc}
              </Typography>
            </CardContent>
          </CardActionArea>
          <CardActions>
            <Button size="small" color="primary" className={classes.desc}>
              {images ? viewImages : readTitle}
            </Button>
          </CardActions>
        </Card>
      </Collapse>
      <MediaDialog
        open={show}
        close={() => setShow(false)}
        folder="slide"
        isVideo={false}
      />
    </>
  );
};

export default withStyles(styles)(ImageCard);
